import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { BrowserRouter as Router, Link } from 'react-router-dom'
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faSearch, faArrowUp, faArrowLeft, faArrowDown, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import comosa from './images/comosa.svg';
import logo from './images/logo-nova.png';


import './components/scss/keyboard.scss';
import 'bootstrap/dist/css/bootstrap.css';
import './components/scss/App.scss'


function App() {

  const [posts, setPosts] = useState({posts:[]});
  const url = `${process.env.REACT_APP_APIDOMAIN}/search`;
  const [caps, setCaps] = useState(false);
  const [result, showResult] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notfound, setNotfound] = useState(false);

  let [text, setText] = useState('');

  function addText(letter){
      text = text + letter;
      setText(text);
  }

  function deleteLetter(){

    if(text.length > 0){
      text = text.slice(0, -1);
      setText(text);
    }
  }

  useEffect(()=>{
      return() => {

      }
  },[text, posts])

  const fetchData = async (url) => {
    console.log(url);
    showResult(true);
    setLoading(true);
    setNotfound(false);

    try {
      let s = {
        search: text
      }
      const result = await axios.post(url, s);        
      const newList = result.data;
      // const newList = result.data.Items;
      console.log("here",newList);
      if(newList.length < 1){
        setNotfound(true);
      }
      setPosts({posts:newList});

      console.log("los nuevos posots", posts);
      setLoading(false);


      // var grouped = _.mapObject(_.groupBy(newList, 'floor'), clist => clist.map(car => _.omit(car, 'floor')));

      console.log(newList);

    } catch (error) {
      console.log("error", error)
    }
  };

  return (
      <div className="page">
        <Router>
          <div className="App">
            <div className="posts buscador">
              <div className="header">
                  <Helmet>
                    <meta name="theme-color" content="#282c34" />  
                    <meta name="a.validate.01" content="0e139ff3e6eb3f35baf51b55c13b14629c65" />  
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                  </Helmet>
                  <div className="logo">
                      <Link to="/">
                        <img src={logo} alt="Directorio NovaReformador" />
                      </Link>
                  </div>
              </div>              
              <div className="keyboard">
                <h2>¿A quién buscas?</h2>
                <>
                  <div className="searchbox">
                    <div className={text === "" ? "searchText" : "searchText active"}>{text === "" ? 'Nombre' : text}</div>
                    <div className="iconSearch" onClick={() => fetchData(url)}>
                        <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                    </div>
                  </div>
                  <div className="searchbox-desktop">
                    <input type="text" placeholder="Escribe algo..." onChange={(e) => setText(e.target.value)}></input>
                    <div className="iconSearchDesktop" onClick={() => fetchData(url)}>
                        <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                    </div>
                  </div>
                  <ul id="keyboard">
                      <li className="letter" onClick={caps ? (e) => addText('Q') : (e) => addText('q') }>{caps ? "Q" : 'q'}</li>
                      <li className="letter" onClick={caps ? (e) => addText('W') : (e) => addText('w') }>{caps ? "W" : 'w'}</li>
                      <li className="letter" onClick={caps ? (e) => addText('E') : (e) => addText('e') }>{caps ? "E" : 'e'}</li>
                      <li className="letter" onClick={caps ? (e) => addText('R') : (e) => addText('r') }>{caps ? "R" : 'r'}</li>
                      <li className="letter" onClick={caps ? (e) => addText('T') : (e) => addText('t') }>{caps ? "T" : 't'}</li>
                      <li className="letter" onClick={caps ? (e) => addText('Y') : (e) => addText('y') }>{caps ? "Y" : 'y'}</li>
                      <li className="letter" onClick={caps ? (e) => addText('U') : (e) => addText('u') }>{caps ? "U" : 'u'}</li>
                      <li className="letter" onClick={caps ? (e) => addText('I') : (e) => addText('i') }>{caps ? "I" : 'i'}</li>
                      <li className="letter" onClick={caps ? (e) => addText('O') : (e) => addText('o') }>{caps ? "O" : 'o'}</li>
                      <li className="letter" onClick={caps ? (e) => addText('P') : (e) => addText('p') }>{caps ? "P" : 'p'}</li>
                      <li className="letter" onClick={caps ? (e) => addText('A') : (e) => addText('a') }>{caps ? "A" : 'a'}</li>
                      <li className="letter" onClick={caps ? (e) => addText('S') : (e) => addText('s') }>{caps ? "S" : 's'}</li>
                      <li className="letter" onClick={caps ? (e) => addText('D') : (e) => addText('d') }>{caps ? "D" : 'd'}</li>
                      <li className="letter" onClick={caps ? (e) => addText('F') : (e) => addText('f') }>{caps ? "F" : 'f'}</li>
                      <li className="letter" onClick={caps ? (e) => addText('G') : (e) => addText('g') }>{caps ? "G" : 'g'}</li>
                      <li className="letter" onClick={caps ? (e) => addText('H') : (e) => addText('h') }>{caps ? "H" : 'h'}</li>
                      <li className="letter" onClick={caps ? (e) => addText('J') : (e) => addText('j') }>{caps ? "J" : 'j'}</li>
                      <li className="letter" onClick={caps ? (e) => addText('K') : (e) => addText('k') }>{caps ? "K" : 'k'}</li>
                      <li className="letter" onClick={caps ? (e) => addText('L') : (e) => addText('l') }>{caps ? "L" : 'l'}</li>
                      <li className="letter" onClick={caps ? (e) => addText('Ñ') : (e) => addText('ñ') }>{caps ? "Ñ" : 'ñ'}</li>
                      <li className="capslock">
                          {caps ?
                          <FontAwesomeIcon icon={faArrowDown} onClick={(e) => setCaps(false)}></FontAwesomeIcon>
                          :
                          <FontAwesomeIcon icon={faArrowUp} onClick={(e) => setCaps(true)}></FontAwesomeIcon>
                          }
                      </li>
                      <li className="letter" onClick={caps ? (e) => addText('Z') : (e) => addText('z') }>{caps ? "Z" : 'z'}</li>
                      <li className="letter" onClick={caps ? (e) => addText('X') : (e) => addText('x') }>{caps ? "X" : 'x'}</li>
                      <li className="letter" onClick={caps ? (e) => addText('C') : (e) => addText('c') }>{caps ? "C" : 'c'}</li>
                      <li className="letter" onClick={caps ? (e) => addText('V') : (e) => addText('v') }>{caps ? "V" : 'v'}</li>
                      <li className="letter" onClick={caps ? (e) => addText('B') : (e) => addText('b') }>{caps ? "B" : 'b'}</li>
                      <li className="letter" onClick={caps ? (e) => addText('N') : (e) => addText('n') }>{caps ? "N" : 'n'}</li>
                      <li className="letter" onClick={caps ? (e) => addText('M') : (e) => addText('m') }>{caps ? "M" : 'm'}</li>
                      <li className="return lastitem" onClick={(e) => deleteLetter()}><FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon></li>
                      <li className="numbers" onClick={(e) => setText('')}>Borrar</li>
                      <li className="space" onClick={(e) => addText(' ')}>espacio</li>
                      <li className="search" onClick={() => fetchData(url)}>buscar</li>
                  </ul>  
                </>              
                <div className="bottom">
                  <h3>¡Bienvenido!</h3>
                  <div className="bottom-left">
                    <div className="icons">
                      <FontAwesomeIcon icon={faFacebookF}></FontAwesomeIcon>
                      <FontAwesomeIcon icon={faInstagram}></FontAwesomeIcon>
                    </div>                  
                  </div>
                  <div className="bottom-center">
                    <img src={comosa} alt="Desarrollado por Comosa"></img>
                  </div>
                  <div className="bottom-right">novareformador.com</div>
                </div>
              </div>
            </div>
            {result &&            
              <div className="results">

                <div className="second-header">
                  <img src={logo} alt="Directorio NovaReformador"></img>
                </div>
                <div className="searchbox extra">
                  <div className={text === "" ? "searchText" : "searchText active"} onClick={() => showResult(false)}>{text === "" ? 'Nombre' : text}</div>
                  <div className="iconSearch" onClick={() => fetchData(url)}>
                      <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
                  </div>
                </div>
                <div className="list-result">
                  {loading && <div>Buscando...</div>}


                  {!notfound && 
                    <div>
                      {posts.posts.map((item,k) => (
                      <div className="list">
                        <h4 className="floor">Nivel {item.floor}</h4>
                        <h5 className="name">{item.commercialName}</h5>
                        <p className="description">{item.description}</p>
                        <p className="office">Oficina {item.office}</p>
                      </div>
                      ))}

                    </div>
                  }

                  {notfound &&
                    <div>No se obtuvieron resultados!</div>
                  }
                </div>

                <div className="back" onClick={() => showResult(false)}>
                  <button className="btn btn-primary btn-custom">
                    <FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon> <span>regresar</span>
                  </button>
                </div>


              </div>
            } 
          </div>
        </Router>
      </div>
  )
}

export default App;
